import axios from 'src/store/api';

export default {

    namespaced: true,

    state: {
        clubs: [],
        club_detail: {}
    },

    mutations: {
        SET_CLUBS(state, data) {
            state.clubs = data;
        },

        SET_CLUB_DETAIL(state, data) {
            state.club_detail = data;
        }
    },

    getters: {
        clubs: state => state.clubs || [],
        club_detail: state => state.club_detail || {}
    },

    actions: {
        async fetchAllClubs({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/clubs`, payload);
                commit('SET_CLUBS', response.data.data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async fetchClubDetail({ commit }, id) {
            try {
                const response = await axios.get(`/api/admin/clubs/${id}`);
                commit('SET_CLUB_DETAIL', response.data.data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async storeCatalog({ commit }, payload) {
            try {
                const response = await axios.post(`/api/admin/catalogs`, payload);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async updateCatalog({ commit }, payload) {
            try {
                const response = await axios.put(`/api/admin/catalogs/${payload.id}`, payload);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async destroy({ commit }, id) {
            try {
                const response = await axios.delete(`/api/admin/catalogs/${id}`);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
  },
}
