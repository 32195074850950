export default [
    {
        path: "/stats/payments",
        redirect: "/stats/payments/all",
        meta: { roles: [1] },
        component: () => import("src/components/Dashboard/Layout/DashboardLayout.vue"),
        children: [
            {
                path: "all",
                name: "ListTicketOrganizers",
                component: () => import("src/components/Dashboard/Views/Stats/All-Payment.vue"),
                meta: {
                    pageTitle: 'Thanh toán'
                }
            }
        ]
    }
];