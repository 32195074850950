import axios from 'src/store/api/api';

export default {

    namespaced: true,

    state: {
        payments: []
    },

    mutations: {
        SET_PAYMENTS(state, data) {
            state.payments = data;
        }
    },

    getters: {
        payments: state => state.payments || []
    },

    actions: {
        async fetchAllPayments({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/payments`, payload);
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },

        async resendIPN({ commit }, payment_id) {
            try {
                const response = await axios.post(`/api/admin/payments/${payment_id}/resend-ipn`);
                return response.data.data;
            } catch (error) {
                throw error;
            }
        }
    }
}
