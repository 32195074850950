import axios from 'src/store/api';

export default {

    namespaced: true,

    state: {
        emailTemplates: [],
        currentEmailTemplate: {}
    },

    mutations: {
        SET_EMAIL_TEMPLATES(state, data) {
            state.emailTemplates = data;
        },

        SET_CURRENT_EMAIL_TEMPLATE(state, data) {
            state.currentEmailTemplate = data;
        }
    },

    getters: {
        emailTemplates: state => state.emailTemplates || [],
        currentEmailTemplate: state => state.currentEmailTemplate || {}
    },

    actions: {
        async fetchAllEmailTemplates({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/v1/default_email_templates`, payload);
                commit('SET_EMAIL_TEMPLATES', response.data.data);
            } catch (error) {
                throw error;
            }
        },

        async fetchAllEmailTemplateById({ commit }, id) {
            try {
                const response = await axios.get(`/api/admin/v1/default_email_templates/${id}`);
                commit('SET_CURRENT_EMAIL_TEMPLATE', response.data.data);
            } catch (error) {
                throw error;
            }
        },

        async syncEmailTemplateFromConfig({ commit }) {
            try {
                const response = await axios.post(`/api/admin/v1/default_email_templates/sync-from-config`);
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },

        async updateEmailTemplate({ commit }, payload) {
            try {
                const response = await axios.put(`/api/admin/v1/default_email_templates/${payload.id}`, payload);
                return response.data.data;
            } catch (error) {
                throw error;
            }
        }
  },
}
