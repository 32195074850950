import axios from './../api'
import { buildQuery } from '../actions'

export default {
    namespaced: true,
    state: {
        charities: [],
        charity_detail: []
    },

    mutations: {
        CHARITIES (state, val) {
            state.charities = val
        },

        CHARITY_DETAIL (state, val) {
            state.charity_detail = val
        }
    },

    getters: {
        charities (state) {
            return state.charities
        },

        charity_detail: state => state.charity_detail,
    },

    actions: {
        async fetchCharities ({ commit }, payload) {
            try {
                const response = await axios.get('/api/admin/charity/list', {
                    params: payload
                })
                commit('CHARITIES', response.data)
                return response.data
            } catch (error) {
                console.error('fetchCharities error:', error)
                throw error
            }
        },

        async fetchCharityDetail (context, id) {
            let { commit, state } = context
            await axios.get('/api/admin/charity/' + id)
                .catch(err => {
                    console.error(err)
                    console.error(err.stack)
                })
                .then(resp => {
                    let data = resp.data
                    commit('CHARITY_DETAIL', data)
                })
        },

        storeCharity (context, form) {
            let { commit, state } = context
            return new Promise((resolve, reject) => {
            axios.post('/api/admin/charity/add', form).then(
                res => {
                    resolve(res.data)
                },
                error => {
                    reject(error)
                })
            })
        },

        updateCharity(context, form) {
            let {commit, state} = context;
            return new Promise((resolve, reject) => {
                axios.post('/api/admin/charity/edit/' + form.get('id'), form)
                .then(resp => {
                    resolve(resp.data);
                }, error => {
                    reject(error);
                });
            });
        },


        deleteCharity(context, id) {
            let {commit, state} = context;
            return new Promise((resolve, reject) => {
                axios.get('/api/admin/charity/delete/' + id)
                .then(resp => {
                    resolve(resp.data);
                }, error => {
                    reject(error);
                });
            });
        },
    }
}
