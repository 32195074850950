<template>
  <div class="user">
    <div class="photo">
      <!--<img src="/static/img/logo_sm.png" alt="">-->
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click="toggleMenu"
      >
        <span>
          {{ name }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="clearfix"></div>
      <el-collapse-transition>
        <ul class="nav nav-menu" style="padding-left: 44px" v-show="!isClosed">
          <li>
            <a href="#" @click="showModalPassowrd = true">
              <span class="sidebar-mini-icon"></span>
              <span class="sidebar-normal">Đổi mật khẩu</span>
            </a>
          </li>
          <li>
            <a href="#" @click="logout">
              <span class="sidebar-mini-icon"></span>
              <span class="sidebar-normal">Đăng xuất</span>
            </a>
          </li>
        </ul>
      </el-collapse-transition>
    </div>
    <el-dialog
      title="Đổi mật khẩu"
      v-model="showModalPassowrd"
      width="30%"
    >
      <el-form
        ref="ruleFormRef"
        :model="form"
        status-icon
        @submit.prevent="submitForm"
      >
        <el-form-item
          prop="old_password"
          :rules="[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu cũ',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            placeholder="Mật khẩu cũ"
            type="password"
            v-model="form.old_password"
          />
        </el-form-item>

        <el-form-item
          prop="new_password"
          :rules="[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu mới',
              trigger: 'change',
            },
          ]"
        >
          <el-input
            placeholder="Mật khẩu mới"
            type="password"
            v-model="form.new_password"
          />
        </el-form-item>
      </el-form>
        <template v-slot:footer>
          <div class="dialog-footer d-flex justify-content-end">
            <el-button @click="showModalPassowrd = false">Hủy</el-button>
            <el-button type="primary" @click="submitForm">Đồng ý</el-button>
          </div>
        </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  ElMessage,
  ElDialog,
  ElButton,
  ElInput,
  ElForm,
  ElFormItem,
  ElCollapseTransition,
} from "element-plus";

export default {
  components: {
    ElDialog,
    ElButton,
    ElInput,
    ElForm,
    ElFormItem,
    ElCollapseTransition,
  },
  data() {
    return {
      isClosed: true,
      name: "",
      current_user: localStorage.getItem("current_user")
        ? JSON.parse(localStorage.getItem("current_user"))
        : "",
      showModalPassowrd: false,
      form: {
        old_password: "",
        new_password: "",
      },
    };
  },
  mounted() {
    this.name = this.current_user.name || "iRace";
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    logout() {
      this.$store.dispatch("authLogout").then((res) => {
        ElMessage({
          message: "Đăng xuất thành công",
          type: "success",
        });
        this.$router.push({ name: "Login" });
      });
    },
    submitForm() {
      let current_user = localStorage.getItem("current_user");
      if (!current_user) {
        ElMessage({
          message: "Chưa đăng nhập",
          type: "error",
        });
        return false;
      }
      current_user = JSON.parse(current_user);
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          var self = this;
          let data = {
            old_password: self.form.old_password,
            new_password: self.form.new_password,
            user_id: current_user.id,
          };
          self.$store.dispatch("updatePassword", data).then(
            (res) => {
              if (res.success) {
                ElMessage({
                  message: "Đổi mật khẩu thành công",
                  type: "success",
                });
                self.showModalPassowrd = false;
              } else {
                ElMessage({
                  message: res.message,
                  type: "error",
                });
              }
            },
            (error) => {
              console.error(error);
            }
          );
        }
      })
    },
  },
};
</script>
<style lang="scss">
.nav.nav-menu {
  margin-top: 0;
}

.old-password {
  margin-bottom: 10px;
}
</style>
