export default [
    {
        path: '/enterprise',
        meta: {
            roles: [1]
        },
        component: () => import('src/components/Dashboard/Layout/DashboardLayout.vue'),
        children: [
            {
                path: 'gift-codes',
                name: 'EnterpriseAllGiftCodes',
                component: () => import('src/components/Dashboard/Views/Enterprise/All-Gift-Codes.vue'),
                meta: {
                    pageTitle: 'Gift Codes'
                }
            }
        ]
    }
];