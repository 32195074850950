export default function setRoles (role) {
    let menus = [];
    if (role == 1) {
        menus = [
          {
            name: "Dashboard",
            icon: "nc-icon nc-app",
            path: "/admin/overview",
          },
          {
            name: "Report",
            icon: "nc-icon nc-tag-content",
            path: "/reports"
          },
          {
            name: "Order - Ticket",
            icon: "nc-icon nc-tag-content",
            path: "/tickets"
          },
            {
                name: "Order - Virtual race",
                icon: "nc-icon nc-tag-content",
                path: "/orders"
            },
            {
                name: "Order - Enterprise",
                icon: "nc-icon nc-tag-content",
                path: "/enterprise/orders"
            },
            {
                name: "Order - VIP",
                icon: "nc-icon nc-tag-content",
                path: "/vip/orders"
            },
          {
            name: "BÁN VÉ",
            icon: "nc-icon nc-tag-content",
            children: [
              {
                name: "Event",
                path: "/events",
              },
              {
                name: "Category",
                path: "/event-categories",
              },
              {
                name: "Addon",
                path: "/event-addons",
              },
              {
                name: "Attribute",
                path: "/attributes",
              },
              {
                name: "Import đơn hàng",
                path: "tickets/import",
              }
            ],
          },
          {
            name: "Virtual Race",
            icon: "nc-icon nc-user-run",
            children: [
              {
                name: "Tất cả cuộc đua",
                path: "/races",
              },
              {
                name: "Doanh nghiệp",
                path: "/enterprise/races",
              },
              {
                name: "Loại cuộc đua",
                path: "/category-race",
              },
            //   {
            //     name: "Chủ đề cuộc đua",
            //     path: "/themes",
            //   },
              {
                name: "Kiểu cuộc đua",
                path: "/race-type",
              },
              {
                name: "Tất cả cự ly",
                path: "/catalogs",
              },
              {
                name: "Địa điểm Offline",
                path: "/locations",
              },
              {
                name: "Xuất data",
                path: "/races/export-data",
              },
              {
                name: "Import data",
                path: "/races/import-data",
              }
            ],
          },
          {
            name: "Banner Ads",
            icon: "nc-icon nc-album-2",
            path: "/banner-ads",
          },
          {
              name: "Đơn vị tổ chức",
              icon: "nc-icon nc-tag-content",
              path: "/organizers/list"
          },
          {
              name: "Nhà tài trợ",
              icon: "nc-icon nc-bank",
              path: "/sponsors/list"
          },
          {
            name: "Sản phẩm",
            icon: "nc-icon nc-user-run",
            children: [
              {
                name: "Danh sách sản phẩm",
                path: "/addons",
              },
              {
                name: "Nhóm sản phẩm",
                path: "/product-categories",
              },
              {
                name: "Đơn hàng",
                path: "/order-products",
              },
            ],
          },
          {
            name: "Quản lý người dùng",
            icon: "nc-icon nc-badge",
            children: [
              {
                name: "Tất cả người dùng",
                path: "/users",
              },
              {
                name: "Bảng",
                path: "/groups",
              },
              {
                name: "Câu lạc bộ",
                path: "/clubs",
              },
              {
                name: "Công ty",
                path: "/companies",
              },
              {
                name: "Trường học",
                path: "/schools",
              },
            ],
          },
          {
            name: "Quản lý đơn hàng",
            icon: "nc-icon nc-single-copy-04",
            children: [
              {
                name: "Đóng góp quỹ từ thiện",
                path: "/donates",
              },
            ],
          },
          {
            name: "VIP",
            icon: "nc-icon nc-tag-content",
            children: [
                {
                    name: "Danh sách gói",
                    path: "/vip/plans"
                },
                {
                    name: "Quà tặng",
                    path: "/vip/gifts"
                },
                {
                    name: "Đổi quà",
                    path: "/vip/claims"
                },
                {
                    name: "Giao diện custom",
                    path: "/vip/vip-theme"
                },
                {
                    name: "Vip codes",
                    path: "/vip/codes"
                },
            ],
          },
          {
            name: "FAQ",
            icon: "nc-icon nc-tag-content",
            children: [
                {
                    name: "Danh sách FAQ",
                    path: "/faqs/list"
                },
            ],
          },
          {
            name: "Testimonials",
            icon: "nc-icon nc-tag-content",
            children: [
                {
                    name: "Danh sách",
                    path: "/testimonials/list"
                },
            ],
          },
          {
            name: "Affiliate",
            icon: "nc-icon nc-spaceship",
            children: [
              {
                name: "Group",
                path: "/aff/groups",
              },
              {
                name: "Partner",
                path: "/aff/partners",
              },
              {
                name: "Commission",
                path: "/aff/commission",
              },
              {
                name: "Withdrawal",
                path: "/withdrawals",
              },
            ],
          },
            {
                name: "Doanh nghiệp",
                icon: "nc-icon nc-bank",
                children: [
                    {
                        name: "Danh sách gói",
                        path: "/enterprise/packages"
                    },
                    {
                        name: "Gift Codes",
                        path: "/enterprise/gift-codes"
                    }
                ]
            },
          {
            name: "Exercise",
            icon: "nc-icon nc-tag-content",
            children: [
              {
                name: "Tạo mới",
                path: "/exercises/create",
              },
              {
                name: "Danh sách",
                path: "/exercises",
              },
            ],
          },
          {
            name: "Quản lý Coupon",
            icon: "nc-icon nc-tag-content",
            children: [
              {
                name: "Tạo mới",
                path: "/coupons/create",
              },
              {
                name: "Danh sách",
                path: "/coupons/all",
              },
              {
                name: "Tìm kiếm",
                path: "/coupons/search",
              },
            ],
          },
          {
            name: "Promotion",
            icon: "nc-icon nc-paper",
            path: "/promotions",
          },
          {
            name: "Quản lý quy định",
            icon: "nc-icon nc-paper",
            path: "/rules",
          },
            {
                name: "Quản lý Mail",
                icon: "nc-icon nc-email-85",
                children: [
                    {
                        name: "Forms",
                        path: "/mail/forms",
                    },
                    {
                        name: "Templates",
                        path: "/mail/templates",
                    },
                    {
                        name: "[Ticket] Email templates",
                        path: "/email-templates",
                    },
                    {
                        name: "Mặc định",
                        path: "/default-email-templates",
                    },
                    {
                        name: "Mail merge",
                        path: "/mail-merge",
                    },
                    {
                        name: "Status",
                        path: "/mail/status",
                    },
                    {
                        name: "Delivery Status",
                        path: "/mail/delivery-status",
                    }
                ]
            },
            {
                name: "Quản lý quỹ từ thiện",
                icon: "nc-icon nc-alert-circle-i",
                path: "/charities",
            },
            {
                name: "Operation & Ship",
                icon: "nc-icon nc-bus-front-12",
                path: "/operation-ship",
            },
            {
                name: "Thiết lập chung",
                icon: "nc-icon nc-settings-gear-65",
                children: [
                    {
                        name: "Banner",
                        path: "/setting-banner",
                    },
                    {
                        name: "iCoin",
                        path: "/icoin",
                    },
                    {
                        name: "Caching",
                        path: "/caching",
                    },
                    {
                        name: "Lịch sử hoạt động",
                        path: "/logs",
                    },
                    {
                        name: "Xử lý gian lận",
                        path: "/cheats",
                    },
                    {
                        name: "Support Tool",
                        path: "/support-tool",
                    }
                ]
            },
          {
            name: "Quản lý hoạt động",
            icon: "nc-icon nc-settings-gear-65",
            children: [
              {
                name: "Workouts",
                path: "/activities/workout",
              },
              {
                name: "Walk",
                path: "/activities/walk",
              },
              {
                name: "Running, Ride",
                path: "/activities/run",
              },
              {
                name: "List Deleted",
                path: "/activities/deleted",
              },
            ],
          },
            {
                name: "Quản trị viên",
                icon: "nc-icon nc-badge",
                path: "/admins",
            },
            {
                name: "OAuth 2.0",
                icon: "nc-icon nc-settings",
                path: "/oauth/clients",
            },
            {
                name: "iStats",
                icon: "nc-icon nc-settings-gear-65",
                children: [
                    {
                        name: "Payment",
                        path: "/stats/payments"
                    }
                ],
              },
        ];
    } else if (role == 2) {
        menus = [
            {
                name: "Quản lý cuộc đua",
                icon: "nc-icon nc-user-run",
                children: [
                    {
                        name: "Tất cả cuộc đua",
                        path: "/races",
                    },
                    {
                        name: "Doanh nghiệp",
                        path: "/enterprise/races"
                    },
                    {
                        name: "Loại cuộc đua",
                        path: "/category-race",
                    },
                    {
                        name: "Chủ đề cuộc đua",
                        path: "/themes",
                    },
                    {
                        name: "Kiểu cuộc đua",
                        path: "/race-type",
                    },
                    {
                        name: "Cự ly",
                        path: "/catalogs",
                    },
                    {
                        name: "Địa điểm Offline",
                        path: "/locations",
                    },
                ],
            },
            {
                name: "Quản lý quy định",
                icon: "nc-icon nc-paper",
                path: "/rules",
            },
            {
                name: "Quản lý quỹ từ thiện",
                icon: "nc-icon nc-alert-circle-i",
                path: "/charities",
            },
        ];
    } else if (role == 3) {
        menus = [
            {
            name: "Quản lý đơn hàng",
            icon: "nc-icon nc-single-copy-04",
            children: [
                {
                    name: "Đăng ký race",
                    path: "/orders",
                },
                {
                    name: "Mua sản phẩm",
                    path: "/order-products",
                },
                {
                    name: "Đóng góp quỹ từ thiện",
                    path: "/donates",
                },
                {
                    name: "Operation & Ship",
                    icon: "nc-icon nc-bus-front-12",
                    path: "/operation-ship",
                }
            ],
            },
            {
            name: "Quản lý người dùng",
            icon: "nc-icon nc-single-02",
            children: [
                {
                    name: "Tất cả người dùng",
                    path: "/users",
                },
                {
                    name: "Bảng",
                    path: "/groups",
                },
                {
                    name: "Câu lạc bộ",
                    path: "/clubs",
                },
                {
                    name: "Công ty",
                    path: "/companies",
                },
                {
                    name: "Trường học",
                    path: "/schools",
                },
            ],
            },
            {
            name: "Quản lý Coupon",
            icon: "nc-icon nc-tag-content",
            children: [
                {
                    name: "Tạo mới",
                    path: "/coupons/create",
                },
                {
                    name: "Danh sách",
                    path: "/coupons/all",
                },
                {
                    name: "Tìm kiếm",
                    path: "/coupons/search",
                },
            ],
            },
        ];
    } else if (role == 4) {
        menus = [
            {
                name: "Report",
                icon: "nc-icon nc-tag-content",
                path: "/reports"
              },
            {
                name: "Order - Ticket",
                icon: "nc-icon nc-tag-content",
                path: "/tickets"
            },
            {
                name: "Order - Virtual race",
                icon: "nc-icon nc-tag-content",
                path: "/orders"
            },
            {
                name: "Order - Enterprise",
                icon: "nc-icon nc-tag-content",
                path: "/enterprise/orders"
            },
            {
                name: "Order - VIP",
                icon: "nc-icon nc-tag-content",
                path: "/vip/orders"
            },
            {
                name: "Quản lý đơn hàng",
                icon: "nc-icon nc-single-copy-04",
                children: [
                    {
                        name: "Mua sản phẩm",
                        path: "/order-products",
                    },
                    {
                        name: "Đóng góp quỹ từ thiện",
                        path: "/donates",
                    },
                    {
                        name: "Operation & Ship",
                        icon: "nc-icon nc-bus-front-12",
                        path: "/operation-ship",
                    }
                ],
            },
            {
                name: "Quản lý người dùng",
                icon: "nc-icon nc-single-02",
                children: [
                    {
                        name: "Tất cả người dùng",
                        path: "/users",
                    },
                    {
                        name: "Bảng",
                        path: "/groups",
                    },
                    {
                        name: "Câu lạc bộ",
                        path: "/clubs",
                    },
                    {
                        name: "Công ty",
                        path: "/companies",
                    },
                    {
                        name: "Trường học",
                        path: "/schools",
                    }
                ]
            },
            {
            name: "Affiliate",
            icon: "nc-icon nc-spaceship",
            children: [
                // {
                //   name: "Group",
                //   path: "/aff/groups",
                // },
                // {
                //   name: "Partner",
                //   path: "/aff/partners",
                // },
                // {
                //   name: "Commission",
                //   path: "/aff/commission",
                // },
                {
                    name: "Withdrawal",
                    path: "/withdrawals"
                }
            ],
            },
        ];
    } else if (role == 5) {
        menus = [
            {
                name: "VIP",
                icon: "nc-icon nc-tag-content",
                children: [
                    {
                        name: "Danh sách gói",
                        path: "/vip/plans"
                    },
                    {
                        name: "Quà tặng",
                        path: "/vip/gifts"
                    },
                    {
                        name: "Đổi quà",
                        path: "/vip/claims"
                    },
                    {
                        name: "Vip codes",
                        path: "/vip/codes"
                    }
                ]
            },
            {
                name: "Testimonials",
                icon: "nc-icon nc-tag-content",
                children: [
                    {
                        name: "Danh sách",
                        path: "/testimonials/list"
                    },
                ],
            },
            {
                name: "FAQ",
                icon: "nc-icon nc-tag-content",
                children: [
                    {
                        name: "Danh sách FAQ",
                        path: "/faqs/list"
                    },
                ],
            },
            {
                name: "Nhà tài trợ",
                icon: "nc-icon nc-bank",
                path: "/sponsors/list"
            }
        ];
    }

    return menus;
}
