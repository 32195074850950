import axios from './../api';
import { buildQuery } from "../actions";

export default {
    namespaced: true,

    state: () => ({
        userDetail: null
    }),

    mutations: {
        SET_USER_DETAIL(state, data) {
            state.userDetail = data;
        }
    },

    getters: {
        userDetail: state => state.userDetail
    },

    actions: {

        async fetchUserDetail({ commit }, userId) {
            try {
                const response = await axios.get(`/api/admin/users/${userId}`);
                commit('SET_USER_DETAIL', response.data.data);
            } catch (error) {
                console.error(error);
            }
        },
        async fetchRaces({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/users/${payload.user_id}/races${buildQuery(payload)}`);
                return response.data.data;
            } catch (error) {
                console.error(error);
            }
        },

        async fetchTickets({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/users/${payload.user_id}/tickets${buildQuery(payload)}`);
                return response.data.data;
            } catch (error) {
                console.error(error);
            }
        },

        async fetchEnterpriseOrders({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/users/${payload.user_id}/enterprises${buildQuery(payload)}`);
                return response.data.data;
            } catch (error) {
                console.error(error);
            }
        },

        async calcRaceUser({ commit }, payload) {
            try {
                const response = await axios.post(`/api/admin/user/calcDistance`, payload);
                return response.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async sendEmailCompletedRace({ commit }, payload) {
            try {
                const response = await axios.post(`/api/admin/user/resendEmailCompleted`, payload);
                return response.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

    }
};
