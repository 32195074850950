
import axios from 'src/store/api';
import api_ticket from 'src/store/api/ticket';

export default {

    namespaced: true,

    state: {
        orderDetail: null,
        orderChanges: null
    },

    mutations: {
        SET_ORDER_DETAIL(state, data) {
            state.orderDetail = data;
        },

        SET_ORDER_CHANGES(state, data) {
            state.orderChanges = data;
        }
    },

    getters: {
        orderDetail: state => state.orderDetail,
        orderChanges: state => state.orderChanges
    },

    actions: {
        async fetchOrderDetail({ commit }, orderId) {
            try {
                const response = await axios.get(`/api/ticket/orders/${orderId}`);
                commit('SET_ORDER_DETAIL', response.data.data);
            } catch (error) {
                console.error(error);
            }
        },

        async sendEmailOrder({ commit }, orderId) {
            try {
                const response = await axios.post(`/api/ticket/orders/${orderId}/send-email-order`);
                return response.data.data;
            } catch (error) {
                console.error(error);
            }
        },

        async updateOrder({ commit }, order) {
            try {
                const response = await axios.put(`/api/ticket/orders/${order.id}`, order);
                return response.data.data;
            } catch (error) {
                console.error(error);
            }
        },

        async getChangeInformationUrl({ commit }, payload) {
            try {
                const response = await api_ticket.post(`/api/admin/order-changes/init`, payload);
                return response.data.data;
            } catch (error) {
                console.error(error);
            }
        },

        async fetchOrderChange({ commit }, id) {
            try {
                const response = await api_ticket.get(`/api/admin/order-changes/${id}`);
                commit('SET_ORDER_CHANGES', response.data.data);
            } catch (error) {
                console.error(error);
            }
        }
  },
}
