import api from 'src/store/api/ticket';

export default {

    namespaced: true,

    state: {
        orders: [],
        currentOrder: {}
    },

    mutations: {
        SET_ORDERS(state, data) {
            state.orders = data;
        },

        SET_CURRENT_ORDER(state, data) {
            state.currentOrder = data;
        }
    },

    getters: {
        orders: state => state.orders || [],
        currentOrder: state => state.currentOrder || {}
    },

    actions: {
        async fetchAllOrders({ commit }, payload) {
            try {
                const response = await api.get(`/api/admin/vip/orders`, payload);
                commit('SET_ORDERS', response.data.data.data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async fetchOrderById({ commit }, id) {
            try {
                const response = await api.get(`/api/admin/vip/orders/${id}`);
                commit('SET_CURRENT_ORDER', response.data.data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async updateOrder({ commit }, payload) {
            try {
                const response = await api.put(`/api/admin/vip/orders/${payload.id}`, payload);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
  },
}
