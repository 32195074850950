import axios from "axios";
import util from 'src/helpers/util';
import router from "src/routes/routes";
import store from 'src/store/store';
import { Service } from 'axios-middleware';

function createApiClient(prefix) {
    const client = axios.create({
        baseURL: prefix,
        timeout: 10000000,
        maxContentLength: 50000000
    });

    const service = new Service(client);

    service.register({
        onRequest(config) {
            config.headers.Authorization = 'Bearer ' + util.getCookie('token');
            return config;
        },
        onResponseError(error) {
            if (error && error.response) {
                let res = error.response;

                if (res.status == 422) {
                    if (res.data && typeof res.data === 'string') {
                        res.data = JSON.parse(res.data);
                    }
                    let errors = res.data.errors;
                    let message = util.parseMessageError422(errors);
                    throw message;
                }

                if (res.status == 401) {
                    util.eraseCookie('token');
                    util.eraseCookie('user');
                    util.eraseCookie('role');
                    store.state.token = '';
                    alert("Phiên làm việc đã hết hạn, vui lòng đăng nhập lại");
                    router.push({ name: 'Login' });
                    return;
                }

                if (res.data && typeof res.data === 'string') {
                    res.data = JSON.parse(res.data);
                    if (res.data.message) throw res.data.message;
                    throw res.data;
                }

                return res;
            }
        }
    });

    return {
        get: (url, params = {}) => client.get(url + util.buildQuery(params)),
        post: (url, data, config = {}) => client.post(url, data, { ...config }),
        put: (url, data) => client.put(url, data),
        delete: (url) => client.delete(url, {})
    };
}

export default createApiClient;
