
import axios from 'src/store/api';

export default {

    namespaced: true,

    actions: {

        async download({commit}, payload) {
            try {
                const response = await axios.post(`/api/admin/download`, payload, {
                    responseType: 'blob'
                });
                const blob = new Blob([response.data]);
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = payload.file_name.split('/').pop();
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(downloadUrl);
            } catch (error) {
                console.error("Lỗi tải file:", error);
            }
        }
    }
}
