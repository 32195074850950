import axios from 'src/store/api';

export default {

    namespaced: true,

    state: {
        gift_codes: [],
        catalog_detail: {}
    },

    mutations: {
        SET_GIFT_CODES(state, data) {
            state.gift_codes = data;
        }
    },

    getters: {
        gift_codes: state => state.gift_codes || []
    },

    actions: {
        async fetchAllGiftCodes({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/enterprise/gift-codes`, payload);
                commit('SET_GIFT_CODES', response.data.data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async storeGiftCode({ commit }, payload) {
            try {
                const response = await axios.post(`/api/admin/enterprise/gift-codes`, payload);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async updateGiftCode({ commit }, payload) {
            try {
                const response = await axios.put(`/api/admin/enterprise/gift-codes/${payload.id}`, payload);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async deleteGiftCode({ commit }, id) {
            try {
                const response = await axios.delete(`/api/admin/enterprise/gift-codes/${id}`);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
  },
}
