import axios from './../api';
import { buildQuery } from "../actions";
export default {
    namespaced: true,
    state: {
        events: [],
        tags: [],
        categories: [],
        eventDetail: [],
        categoryDetail: [],
        addonDetail: [],
        eventSeasons: [],
        addons: [],
        event_config: [],
        email_templates: [],
        typesBanner: [],
        input_fields: {
            personal_information: {
                title: 'Thông tin cá nhân',
                fields: {
                    name: 'Tên',
                    email: 'Email',
                    phone: 'Số điện thoại',
                    birthday: 'Ngày sinh',
                    gender: 'Giới tính',
                    identity_card: 'CCCD/Hộ chiếu',
                    club: 'Câu lạc bộ',
                    nationality: 'Quốc gia',
                    postcode: 'Mã bưu chính',
                    province: 'Tỉnh/TP',
                    district: 'Quận/huyện',
                    ward: 'Phường/xã',
                    address: 'Địa chỉ'
                }
            },
            medical_information: {
                title: 'Thông tin y tế',
                fields: {
                    blood: 'Nhóm máu',
                    health_status: 'Tình trạng sức khỏe',
                    medical_history: 'Tiểu sử bệnh lý',
                    medication_being_used: 'Thuốc đặc trị đang sử dụng',
                    allergy_medication: 'Dị ứng thuốc',
                    insurances: 'Bảo hiểm',
                    emergency_people: 'Người liên hệ khẩn cấp',
                    emergency_phone: 'Số điện thoại',
                    emergency_relationship: 'Mối quan hệ',
                }
            }
        }
    },

    getters: {
        default_input_fields: state => {

            let fields = state.input_fields;

            const prevent_edit_items = ['name', 'email', 'phone', 'birthday', 'gender'];
            const not_required_items = ['postcode', 'club', 'insurances'];

            let data = {};

            for (let key in fields) {
                const value = fields[key];

                let childs = {};
                for (let child_key in value.fields) {
                    childs[child_key] = {
                        title: value.fields[child_key],
                        is_required: not_required_items.includes(child_key) ? false : true,
                        is_display: true,
                        is_disabled: prevent_edit_items.includes(child_key) ? true : false
                    }
                }

                data[key] = {
                    is_display: true,
                    title: value.title,
                    fields: childs
                }
            }

            return data;
        },

        season_types() {
            return {
                main: 'Giai đoạn chính',
                flash_sale: 'Flash sale'
            };
        },

        season_allow_apply_discounts() {
            return {
                group: 'Giảm giá nhóm',
                coupon: 'Mã giảm giá'
            };
        },

        event_types() {
            return {
                run_ticket: 'Vé chạy bộ (có nhập thông tin người tham gia)',
                movie_ticket: 'Vé xem phim, ca nhạc (có chọn ghế ngồi, không nhập thông tin người tham gia)',
                simple_ticket: 'Bán sản phẩm (không chọn ghế ngồi, không nhập thông tin người tham gia)'
            }
        },

        tags: state => {
            return state.tags;
        },

        inventory_objects() {
            return {
                events: 'Toàn sự kiện',
                event_catalogs: 'Theo cự ly',
                event_seasons: 'Theo giai đoạn',
                event_prices: 'Cự ly trong giai đoạn',
                flash_sales: 'Flash sale'
            };
        }

    },

    mutations: {
        EVENTS(state, val) {
            state.events = val;
        },

        ALL_TAGS(state, val) {
            state.tags = val;
        },

        CATEGORIES(state, val) {
            state.categories = val;
        },

        ADDONS(state, val) {
            state.addons = val;
        },

        EVENT_DETAIL(state, val) {
            state.eventDetail = val;
        },

        CATEGORY_DETAIL(state, val) {
            state.categoryDetail = val;
        },

        ADDON_DETAIL(state, val) {
            state.addonDetail = val;
        },

        EVENT_SEASONS(state, val) {
            state.eventSeasons = val;
        },

        CONFIG(state, val) {
            state.config = val;
        },

        EMAIL_TEMPLATES(state, val) {
            state.email_templates = val;
        },
        SET_TYPES_BANNER(state, val) {
            state.typesBanner = val;
        }

    },

    actions: {

        all(ctx, payload) {
            let {commit, state} = ctx;
            axios.get('/api/admin/events', { params: payload })
            .then(response => {
                commit('EVENTS', response.data.data);
            })
            .catch(error => {
                console.error('fetchEvents: ', error);
            });
        },

        email_templates(ctx, payload) {
        let {commit, state} = ctx;
        axios.get('/api/admin/email_templates')
            .then(response => {
            commit('EMAIL_TEMPLATES', response.data.data);
            });
        },

        config(ctx, payload) {
        let {commit, state} = ctx;
        axios.get('/api/admin/events/config', { params: payload })
            .then(response => {
            commit('CONFIG', response.data.data);
            })
            .catch(error => {
            console.error('fetchEvents: ', error);
            });
        },

        async fetch(ctx, params) {
            try {
                return await axios.get('/api/admin/events' + buildQuery(params));
            } catch (error) {
                console.log(error);
            }
        },

        async fetchCategory(ctx, params) {
            try {
                return await axios.get('/api/admin/event-categories' + buildQuery(params));
            } catch (error) {
                console.log(error);
            }
        },

        async fetchAddon(ctx, params) {
        try {
            return await axios.get('/api/admin/event-addons' + buildQuery(params));
        } catch (error) {
            console.log(error);
        }
        },

        detail(ctx, id) {
        let { commit, state } = ctx;
        axios.get('/api/admin/events/' + id)
            .then(response => {
            commit('EVENT_DETAIL', response.data.data);
            })
            .catch(error => {
            console.error('fetchEventDetail: ', error);
            });
        },

        categoryDetail(ctx, id) {
        let { commit, state } = ctx;
        axios.get('/api/admin/event-categories/' + id)
            .then(response => {
            commit('CATEGORY_DETAIL', response.data.data);
            })
            .catch(error => {
            console.error('fetchCategoryDetail: ', error);
            });
        },

        addonDetail(ctx, id) {
        let { commit, state } = ctx;
        axios.get('/api/admin/event-addons/' + id)
            .then(response => {
            commit('ADDON_DETAIL', response.data.data);
            })
            .catch(error => {
            console.error('fetchAddonDetail: ', error);
            });
        },

        seasons(ctx, id) {
        let { commit, state } = ctx;
        axios.get('/api/admin/events/' + id + '/seasons')
            .then(response => {
            commit('EVENT_SEASONS', response.data.data);
            })
            .catch(error => {
            console.error('fetchSeasons: ', error);
            });
        },

        store(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/events', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        storeCategory(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/event-categories', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        storeAddon(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/event-addons', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        storeVariant(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/addon-variants', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => reject(error))
        })
        },

        storeDiscountGroup(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/event-discounts', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        storeEmailTemplate(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/email_templates', data)
            .then(response => {
                if (response.status != 200) throw response.data.message;
                resolve(response.data);
            })
            .catch(error => reject(error));
        })
        },

        storeExtendField(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/event-extend-fields', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        createOrUpdateSeason(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/events/seasons', data)
            .then(response => {
                if (response.status != 200) throw response.data.message;
                resolve(response.data);
            })
            .catch(error => reject(error))
        })
        },

        createOrUpdatePrice(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/events/seasons/prices', data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        storePersonalDiscount(ctx, data) {
            return new Promise((resolve, reject) => {
            axios
                .post('/api/admin/events/'+ data.event_id +'/personal/discounts', data)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        },

        update(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .put('/api/admin/events/' + data.id, data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        updateCategory(ctx, formData) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/event-categories/update', formData)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        updateAddon(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .put('/api/admin/event-addons/' + data.id, data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        updateVariant(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .put('/api/admin/addon-variants/' + data.id, data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        deleteVariant(ctx, id) {
        return new Promise((resolve, reject) => {
            axios
            .delete(`/api/admin/addon-variants/${id}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        
        },

        updateDiscountGroup(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .put('/api/admin/event-discounts/' + data.id, data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        updateEmailTemplate(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .put('/api/admin/email_templates/' + data.id, data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        updateExtendField(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
            .put('/api/admin/event-extend-fields/' + data.id, data)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        delete(ctx, id) {
        return new Promise((resolve, reject) => {
            axios
            .delete('/api/admin/events/' + id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        deleteCategory(ctx, id) {
        return new Promise((resolve, reject) => {
            axios
            .delete('/api/admin/event-categories/' + id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        deleteAddon(ctx, id) {
        return new Promise((resolve, reject) => {
            axios
            .delete('/api/admin/event-addons/' + id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        deleteDiscount(ctx, id) {
        return new Promise((resolve, reject) => {
            axios
            .delete('/api/admin/event-discounts/' + id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        deleteEmailTemplate(ctx, id) {
        return new Promise((resolve, reject) => {
            axios
            .delete('/api/admin/email_templates/' + id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        deleteSeason(ctx, id) {
        return new Promise((resolve, reject) => {
            axios
            .delete('/api/admin/events/seasons/' + id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        getRules(ctx, event_id) {
        return new Promise((resolve, reject) => {
            axios
            .get(`/api/admin/events/${event_id}/rules`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        updateRules(ctx, obj) {
        return new Promise((resolve, reject) => {
            axios
            .post(`/api/admin/events/${obj.event_id}/rules`, obj)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        async fetchBannerAds({commit}, params) {
        try {
            return await axios.get('/api/admin/banner_ads' + buildQuery(params));
        } catch (error) {
            console.log(error);
        }
        },

        fetchTypeBannerAds({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get('/api/admin/banner_ads/types')
            .then(response => {
            commit('SET_TYPES_BANNER', response.data.data);
            })
            .catch(error => {
            console.error('fetchEventDetail: ', error);
            });
        })
        },

        createBannerAds({commit}, formData) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/banner_ads', formData)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        fetchBannerAdsDetail({commit}, id) {
        return new Promise((resolve, reject) => {
            axios
            .get(`/api/admin/banner_ads/${id}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        updateBannerAds({commit}, formData) {
        return new Promise((resolve, reject) => {
            axios
            .post('/api/admin/banner_ads/update', formData)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        deleteBannerAds({commit}, id) {
        return new Promise((resolve, reject) => {
            axios
            .delete(`/api/admin/banner_ads/${id}`)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
        })
        },

        storeCoupons({commit}, data) {
            return new Promise((resolve, reject) => {
            axios
                .post('/api/admin/events/' + data.id + '/coupons', data)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        },

        storeShortCode({commit}, data) {
            return new Promise((resolve, reject) => {
            axios
                .post('/api/admin/email_templates/shortcodes', data)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        },

        deleteShortCode({commit}, id) {
            return new Promise((resolve, reject) => {
            axios
                .delete('/api/admin/email_templates/shortcodes/' + id)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        },

        getCatalogs({commit}, event_id) {
            return new Promise((resolve, reject) => {
            axios
                .get('/api/admin/events/' + event_id + '/catalogs')
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        },

        getSeats({commit}, event_id) {
            return new Promise((resolve, reject) => {
            axios
                .get('/api/admin/events/' + event_id + '/seats')
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        },

        tags(ctx, payload) {
            let {commit, state} = ctx;
            axios.get('/api/admin/tags').then(response => {
                commit('ALL_TAGS', response.data.data);
            });
        },

        updateSeats({commit}, data) {
            return new Promise((resolve, reject) => {
            axios
                .post('/api/admin/events/seats', data)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        },

        updateInventoryObjects({commit}, data) {
            return new Promise((resolve, reject) => {
            axios
                .post(`/api/admin/events/${data.event_id}/inventory-objects`, data)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
            })
        }
    }
}
