
import axios from 'src/store/api';
import { buildQuery } from "src/store/actions";

export default {

    namespaced: true,

    state: {
        config: {},
        races: [],
        list_check_points: null,
        report_fields: {},
    },

    mutations: {

        CONFIG(state, val) {
            state.config = val;
        },

        SET_LIST_CHECKPOINT(state, payload) {
            state.list_check_points = payload;
        },

        RACES(state, val) {
            state.races = val;
        },

        REPORT_FIELDS(state, val) {
            state.report_fields = val;
        }


    },

    getters: {
        config: (state) => state.config,
        sport_types: (state) => state.config.sport_types || {},
        races: (state) => state.races,
        list_check_points: (state) => state.list_check_points,
        report_fields: (state) => state.report_fields
    },

    actions: {

        fetchConfig(ctx) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/races/config")
                .then((response) => {
                    commit("CONFIG", response.data.data);
                })
                .catch((error) => {
                    console.error("config: ", error);
                });
        },

        fetchReportFields(ctx, race_id = null) {
            let { commit, state } = ctx;
            let url = "/api/admin/races/report-fields";
            if (race_id) {
                url += `?race_id=${race_id}`;
            }

            axios
                .get(url)
                .then((response) => {
                    commit("REPORT_FIELDS", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchReportFields: ", error);
                });
        },


        fetchRaces(ctx, params) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/races" + buildQuery(params))
                .then((response) => {
                    commit("RACES", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchEvents: ", error);
                });
        },



        storeRace(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/races`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },


      async fetchCheckPoint({commit}, params) {
          try {
            const res = await axios.get('/api/admin/checkpoints' + buildQuery(params));
            commit('SET_LIST_CHECKPOINT', res.data.data)
          } catch (error) {
            console.log(error);
          }
      },

      async createCheckPoint({commit}, formData) {
        return await axios.post('/api/admin/checkpoints', formData);
      },

      async updateCheckPoint({commit}, formData) {
        return await axios.post(`/api/admin/checkpoints/${formData.get('id')}`, formData);
      },

      async deleteCheckPoint({commit}, id) {
        return await axios.delete(`/api/admin/checkpoints/${id}`);
      },

        async updateJourney({ commit }, formData) {
            try {
                const response = await axios.post('/api/admin/races/update-journey', formData);
                return response.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

      exportRaceUser(context, object) {
          let {commit, state} = context;
          return new Promise((resolve, reject) => {
              axios
                  .post('/api/admin/races/export-race-user', object)
                  .then(res => resolve(res.data))
                  .catch(err => reject(err));
          });
      },

        calcRaceResult(context, object) {
            let {commit, state} = context;
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/admin/races/calc-result', object)
                    .then(res => resolve(res.data))
                    .catch(err => reject(err));
            });
        },

        importRaceUser(context, object) {
            let { commit, state } = context;
            return new Promise((resolve, reject) => {
              axios
                .post('/api/admin/races/import-race-user', object, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                .then((res) => resolve(res.data))
                .catch((err) => reject(err));
            });
        },

        downloadImportTemplate(ctx, params) {
            let { commit, state } = ctx;
            axios
            .get("/api/admin/races/download/import-user-template" + buildQuery(params))
            .then((response) => {
                const link = document.createElement("a");
                link.href = response.data.data;
                link.setAttribute("download", "import-user-template.xlsx");
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                console.error("downloadImportTemplate: ", error);
            });
        }
  },
}
