
import axios from 'src/store/api';

export default {

    namespaced: true,

    state: {

    },

    mutations: {

    },

    getters: {
        
    },

    actions: {
        async exportOrders({ commit }, payload) {
            try {
                const response = await axios.post(`/api/admin/orders/export-virtual-races`, payload);
                return response.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

  },
}
