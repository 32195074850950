<template>
    <navbar :show-navbar="showNavbar" :transparent="false" :position="'fixed'">
        <div class="navbar-wrapper">
            <navbar-toggle-button />
            <a class="navbar-brand">{{ pageTitle }}</a>
        </div>

        <template v-slot:navbar-menu>
            <el-button
                v-for="(action, index) in actions"
                :key="index" class="m-0"
                :type="['Lưu lại', 'Cập nhật'].includes(action.label) || !action.type ? 'primary'
                    : action.label.includes('Xóa') ? 'danger'
                    : action.type"
                size="large"
                icon 
                @click="action.callback"
            >

                <i v-if="action.label == 'Cập nhật'" class="fa fa-floppy-o mr-2"></i>
                <i v-else-if="action.label == 'Lưu lại'" class="fa fa-floppy-o mr-2"></i>
                <i v-else-if="action.label == 'Tạo mới'" class="fa fa-plus mr-2"></i>
                <i v-else-if="action.label.includes('Gửi email')" class="fa fa-paper-plane-o mr-2"></i>
                <i v-else :class="`${action.icon} mr-2`"></i>
                {{ action.label || action.title }}
            </el-button>
    </template>
  </navbar>
</template>
<script>
import {
  Navbar,
  NavbarToggleButton,
  Modal,
  FgInput,
} from "src/components/UIComponents";
import { ElButton } from "element-plus";
import { mapState } from "vuex";

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    Modal,
    FgInput,
    ElButton,
  },
  data() {
    return {
      activeNotifications: false,
      showNavbar: false,
      modals: {
        classic: false,
      },
    };
  },

    computed: mapState({
        actions: "currentActions",
        pageTitle: "pageTitle",
    }),

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    dispatchAction(action, link) {
      if (link === undefined) {
        this.$store.dispatch(action);
      } else {
        this.$store.dispatch(action, link);
      }
    },
  },
};
</script>
<style></style>
