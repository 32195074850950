import axios from "axios";
import util from '../helpers/util';
import router from "../routes/routes";
import store from './store';
import { Service } from 'axios-middleware';

let prefix = process.env.VUE_APP_APP_BASE_URL;

const client = axios.create({
    baseURL: prefix,
    timeout: 10000000,
    maxContentLength: 50 * 1024 * 1024
});

const service = new Service(client);

service.register({
    onRequest(config) {
        config.headers.Authorization = 'Bearer ' + util.getCookie('token');
        return config;
    },
    onResponseError(error) {
        if (error && error.response) {

            let res = error.response;

            if (res.status == 422) {
                if (res.data && typeof res.data === 'string') {
                    res.data = JSON.parse(res.data);
                }
                let errors = res.data.errors;
                let message = util.parseMessageError422(errors);
                throw message;
            }

            if (res.status == 401) {
                util.eraseCookie('token');
                util.eraseCookie('user');
                util.eraseCookie('role');
                store.state.token = '';
                alert("Phiên làm việc đã hết hạn, vui lòng đăng nhập lại");
                router.push({name: 'Login'});
                return;
            }

            if (res.data && typeof res.data === 'string') {
                res.data = JSON.parse(res.data);
                if (res.data.message) throw res.data.message;
                throw res.data;
            }

            return res;
        }
    }
});

let api = {
    get: function(url, params = {}) {
        return client.get(prefix + url + util.buildQuery(params));
    },
    post: function(url, data, config = {}) {
        return client.post(prefix + url, data, { ...config });
    },
    put: function(url, data) {
        return client.put(prefix + url, data);
    },
    delete: function(url) {
        return client.delete(prefix + url, {});
    }
}

export default api;
