import _ from "lodash";
import moment from "moment";

const nameHash = {
  products: "product",
  product: "product",

  collections: "collection",
  collection: "collection",

  articles: "article",
  article: "article",

  blogs: "blog",
  blog: "blog",

  pages: "page",
  page: "page",

  galleries: "gallery",
  gallery: "gallery",

  customers: "customer",
  customer: "customer",

  roles: "role",
  role: "role",
};

const multipleTypes = [
  "products",
  "collections",
  "articles",
  "blogs",
  "pages",
  "galleries",
  "roles",
  "customers",
];

const util = {
    findModel(_name) {
        let name = _name.toLowerCase();
        return nameHash[name];
    },

    isMultiple(type) {
        return _.includes(multipleTypes, type);
    },

  nestedSet(hash, field, value) {
    let levels = field.split(".");
    let obj = hash;
    let lastLevel = levels.pop();
    for (let level of levels) {
      if (!obj[level]) {
        obj[level] = {};
      }
      obj = obj[level];
    }
    obj[lastLevel] = value;
  },
  nestedGet(hash, field) {
    let levels = field.split(".");
    let obj = hash;
    let lastLevel = levels.pop();
    for (let level of levels) {
      if (!obj[level]) {
        obj[level] = {};
      }
      obj = obj[level];
    }
    return obj[lastLevel];
  },

  hashFlatten(hash) {
    // {seo: {meta_title: ''}} => seo.meta_title
    let result = {};

    function setValue(hash, result, parent) {
      for (let key of Object.keys(hash)) {
        if (typeof hash[key] != "object") {
          result[parent + key] = hash[key];
        } else {
          setValue(hash[key], result, parent + key + ".");
        }
      }
      return result;
    }

    return setValue(hash, result, "");
  },
  hashNested(hash) {
    let result = {};
    // seo.meta_title => {seo: {meta_title: ''}}
    for (let key of Object.keys(hash)) {
      util.nestedSet(result, key, hash[key]);
    }
    return result;
  },
  createHandle(str) {
    if (str) {
      str = str + "";
      str = str.trim();
      str = str.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/\,/g, "-");
      str = str.replace(/\./g, "-");
      str = str.replace(/\!/g, "-");
      str = str.replace(/\?/g, "-");
      str = str.replace(/\~/g, "-");
      str = str.replace(/\ /g, "-");
      str = str.replace(/\|/g, "-");
      str = str.replace(/\./g, "-");
      str = str.replace(/\"/g, "-");
      str = str.replace(/\'/g, "-");
      str = str.replace(/\-\-+/g, "-");
      str = str.replace(/\s+/g, "-");
      str = str.replace(/[^\w\-]+/g, "");
      str = str.replace(/\-\-+/g, "-");
      str = str.replace(/^-+/, "");
      str = str.replace(/-+$/, "");
      if (str.slice(-1) == "-") str = str.substring(0, str.length - 1);
    }
    return str;
  },

  parseMessageError422(errors) {
    if (typeof errors !== "object") return "";
    let messages = "";
    for (let item in errors) {
      messages += `<div style="margin-bottom: 5px;">${errors[item][0]}</div>`;
    }
    return messages;
  },

    formatMoney(value, unit = "đ") {
        if (unit == "đ") {
            let val = (value / 1).toFixed(0).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "đ";
        }
        const decimal = value % 1 === 0 ? 0 : 2;
        const val = (value / 1).toFixed(decimal);
        return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    formatNumber(value) {
        if (!value) return 0;
        if (Number.isInteger(value)) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            let val = value.toFixed(2).replace(".", ",");
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },

    secondsToTime(seconds) {
        if (!seconds) return '';
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    },

    validateEmail(email) {
        var regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    },

  canvasRender(imageUrl, canvas, callback) {
    let ctx = canvas.getContext("2d");
    let img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      callback(canvas, ctx, imageUrl);
    };
    img.src = imageUrl;
    img.setAttribute("crossOrigin", "anonymous");
  },
  parseCertData(obj) {
    return {
      fullname: obj.fullname,
      successText: "successfully completed".toUpperCase(),
      archivementText:
        `category: ${obj.category} | PACE ${obj.pace}`.toUpperCase(),
      timeText: obj.moving_time,
      rankText: "RANKING: #" + obj.rank,
    };
  },
  //obj: /api/userraces/:id => data = parseCertData(obj)
  renderCertCanvas(canvas, ctx, data) {
    const FONT_SIZE_LARGE = 120;
    const FONT_SIZE_MEDIUM = 110;
    const FONT_SIZE_SMALL = 40;
    const TOP_PERCENTAGE = 55;
    const PADDING_PERCENTAGE = 60;

    ctx.textAlign = "center";
    let left = Math.floor(canvas.width / 2);
    let top = (TOP_PERCENTAGE / 100) * canvas.height - FONT_SIZE_LARGE / 2;
    top = Math.floor(top);

    ctx.font = FONT_SIZE_LARGE + "px Arial";
    ctx.fillText(data.fullname, left, top);
    top += FONT_SIZE_LARGE * (PADDING_PERCENTAGE / 100);

    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);
    ctx.font = FONT_SIZE_SMALL + "px Arial";
    ctx.fillText(data.successText, left, top);
    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);

    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);
    ctx.font = FONT_SIZE_SMALL + "px Arial";
    ctx.fillText(data.archivementText, left, top);
    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);

    top += FONT_SIZE_MEDIUM * (PADDING_PERCENTAGE / 100);
    top += FONT_SIZE_SMALL * (PADDING_PERCENTAGE / 100);
    ctx.font = FONT_SIZE_MEDIUM + "px Arial";
    ctx.fillText(data.timeText, left, top);
    top += FONT_SIZE_MEDIUM * (PADDING_PERCENTAGE / 100);

    ctx.font = FONT_SIZE_SMALL + "px Arial";
    ctx.fillText(data.rankText, left, top);
  },
  //data: /api/userraces/:id
  renderBibCanvas(canvas, ctx, data) {
    const FONT_SIZE = 200;
    const TOP_PERCENTAGE = 80;
    const text = data.user_id;

    ctx.font = FONT_SIZE + "px Verdana";
    ctx.textAlign = "center";

    let left = Math.floor(canvas.width / 2);
    let top = (TOP_PERCENTAGE / 100) * canvas.height - FONT_SIZE / 2;
    top = Math.floor(top);

    ctx.fillText(text, left, top);
  },
  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  eraseCookie(name) {
    this.setCookie(name, "", 0);
  },

    formatDate(date) {
        if (!date) return null;
        return moment(date).format("DD/MM/YYYY");
    },

    copyText(text) {
        navigator.clipboard.writeText(text)
            .then(() => {

            })
            .catch(err => {
                console.error('Failed to copy text:', err);
            });
    },

    ticketHost() {
        return process.env.VUE_APP_TICKET_BASE_URL;
    },

    formatDateTime(datetime) {
        if (!datetime) return null;
        return moment(datetime).format("HH:mm, DD/MM/YYYY");
    },

    fullFormatDateTime(datetime) {
        if (!datetime) return null;
        return moment(datetime).format("HH:mm:ss, DD/MM/YYYY");
    },

  hashTitle(key) {
    if (!key) return null;
    const obj = {
      active: "Hiển thị",
      inactive: "Ẩn",
      sold_out: "Hết hàng",
    };
    return obj[key] || "N/A";
  },

  hashColor(key) {
    const obj = {
      active: "success",
      inactive: "danger",
      sold_out: "danger",
    };
    return obj[key] || "default";
  },
  convertFormData(value) {
    const newObject = {};
    for (const key in value) {
      if (value[key] !== null) {
        newObject[key] = value[key];
      }
    }
    return newObject;
  },
  replaceFormData(data) {
    const listCheck = ['mapbox_coordinates', 'mapbox_points', 'geojson'] ;
    const formData = new FormData();
    for (const key in data) {
      if (data[key] !== null && data[key] !== undefined && data[key] !== '') {
        if (Array.isArray(data[key])) {
          for (let i = 0; i < data[key].length; i++) {
            if (typeof data[key][i] === 'object' && Object.keys(data[key][i]).length > 0) {
              formData.append(`${key}[${i}]`, JSON.stringify(data[key][i]));
            } else {
              formData.append(`${key}[${i}]`, data[key][i]);
            }
          }
        } else if (!listCheck.includes(key) && typeof data[key] === 'object' && Object.keys(data[key]).length > 0) {
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
    };
    return formData;
  },

    dataTableVietnamese() {
        return {
            "processing": "Đang xử lý...",
            "aria": {
                "sortAscending": ": Sắp xếp thứ tự tăng dần",
                "sortDescending": ": Sắp xếp thứ tự giảm dần"
            },
            "emptyTable": "Không có dữ liệu",
            "info": "Hiển thị _START_ tới _END_ của _TOTAL_ dữ liệu",
            "infoEmpty": "Hiển thị 0 tới 0 của 0 dữ liệu",
            "lengthMenu": "Hiển thị _MENU_ dữ liệu",
            "loadingRecords": "Đang tải...",
            "paginate": {
                "first": "Đầu tiên",
                "last": "Cuối cùng",
                "next": "Sau",
                "previous": "Trước"
            },
            "search": "Tìm kiếm:",
            "zeroRecords": "Không tìm thấy kết quả",
            "decimal": ",",
            "infoFiltered": "(được lọc từ _MAX_ dữ liệu)",
            "searchPlaceholder": "Nhập từ khóa",
            "infoThousands": ".",
            "thousands": "."
        };
    },

    buildQuery(params) {
        if (!params) {
            return '';
        }

        if (typeof params === 'string') {
            return params;
        }

        if (typeof params !== 'object') {
            return '';
        }

        if (!Object.keys(params).length) {
            return '';
        }
        
        let arr = [];

        for (let key of Object.keys(params)) {
            if (typeof params[key] != 'undefined') {
                arr.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
            }
        }
        return '?' + arr.join('&');
    }
};

export default util;
