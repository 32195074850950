import axios from 'src/store/api';

export default {

    namespaced: true,

    state: {
        catalogs: [],
        catalog_detail: {}
    },

    mutations: {
        SET_CATALOGS(state, data) {
            state.catalogs = data;
        },

        SET_CATALOG_DETAIL(state, data) {
            state.catalog_detail = data;
        }
    },

    getters: {
        catalogs: state => state.catalogs || [],
        catalog_detail: state => state.catalog_detail || {},
        units() {
            return [
                { value: "km", label: "km" }, 
                { value: "step", label: "bước chân" },
                { value: "times", label: "cái" },
                { value: "day", label: "ngày" }
            ]
        }
    },

    actions: {
        async fetchAllCatalogs({ commit }, payload) {
            try {
                const response = await axios.get(`/api/admin/catalogs`, payload);
                commit('SET_CATALOGS', response.data.data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async fetchCatalogDetail({ commit }, id) {
            try {
                const response = await axios.get(`/api/admin/catalogs/${id}`);
                commit('SET_CATALOG_DETAIL', response.data.data);
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async storeCatalog({ commit }, payload) {
            try {
                const response = await axios.post(`/api/admin/catalogs`, payload);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async updateCatalog({ commit }, payload) {
            try {
                const response = await axios.put(`/api/admin/catalogs/${payload.id}`, payload);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async destroy({ commit }, id) {
            try {
                const response = await axios.delete(`/api/admin/catalogs/${id}`);
                return response.data.data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }
  },
}
